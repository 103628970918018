.leaflet-container {
  width: 100%;
  margin: 0 auto;
  background: #bfe8fe;
}

.leaflet-events-map {
  height: 600px;
}

.leaflet-radius-map {
  height: 350px;
}

.form-panel > .panel-collapse > .panel-body {
  padding: 30px;
}

.circleLatest {
  height: 25px;
  width: 25px;
  border: 4px solid #cc00ff;
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
}

.circle1 {
  height: 5px;
  width: 5px;
  border: 1px solid #000;
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
}

.circle2 {
  height: 10px;
  width: 10px;
  border: 1px solid #000;
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
}

.circle3 {
  height: 15px;
  width: 15px;
  border: 1px solid #000;
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
}

.circle4 {
  height: 20px;
  width: 20px;
  border: 1px solid #000;
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
}

.circle5 {
  height: 25px;
  width: 25px;
  border: 1px solid #000;
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
}

.circle6 {
  height: 30px;
  width: 30px;
  border: 1px solid #000;
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
}

.circle7 {
  height: 35px;
  width: 35px;
  border: 1px solid #000;
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
}

.ageDay {
  height: 20px;
  width: 20px;
  border: 1px solid #000;
  background-color: red;
  border-radius: 10%;
  margin: 2px;
  display: inline-block;
}

.ageWeek {
  height: 20px;
  width: 20px;
  border: 1px solid #000;
  background-color: orange;
  border-radius: 10%;
  margin: 2px;
  display: inline-block;
}

.ageMonth {
  height: 20px;
  width: 20px;
  border: 1px solid #000;
  background-color: yellow;
  border-radius: 10%;
  margin: 2px;
  display: inline-block;
}

.ageOlder {
  height: 20px;
  width: 20px;
  border: 1px solid #000;
  background-color: white;
  border-radius: 10%;
  margin: 2px;
  display: inline-block;
}


